import React, { useEffect, useState } from "react";
import "./dist/DeniedAccess.css";
import Back from "../../components/Back/Back";
import { useNavigate } from "react-router-dom";

export default function DeniedAccess() {
  const nav = useNavigate();
  const [countSeconds, setCountSeconds] = useState(5);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countSeconds === 0) return nav("/");
      setCountSeconds((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countSeconds, nav]);
  return (
    <div className="denied-access-wrapper">
      <Back />
      <div className="denied-access">
        <div className="denied-access-img-container">
          <img
            className="denied-access-img"
            src={process.env.PUBLIC_URL + "/img/DeniedAccess/deniedAccess.png"}
            alt="denied access"
          />
        </div>
        <div className="denied-access-text">
          <h2 className="denied-access-title">
            От халепа! Щось пішло не так :(
          </h2>
          <span>
            Будьте готові, поки ми виправимо цю помилку. Ви також можете оновити
            сторіку або повторити спробу пізніше.
          </span>
          <p>{countSeconds}</p>
        </div>
      </div>
    </div>
  );
}
