import React from "react";
import "./dist/LoadingPage.css";
import Loader from "../../components/Loader/Loader";

export default function LoadingPage() {
  return (
    <div className="loading-page">
      <Loader />
    </div>
  );
}
