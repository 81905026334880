import React from "react";
import "./dist/Rules.css";
import Back from "../../components/Back/Back";
import RulePiece from "../../components/RulePiece/RulePiece";

const rulesArray = [
  {
    title: "Контент",
    description:
      "Використовувати унікальні назви, які не порушують авторські права і не єобразливими",
    allowed:
      "Освітні, розважальні, інформаційні відео, огляди продуктів, блоги, музика, ігрові стріми тощо.",
    forbidden:
      "Контент, який порушує авторські права, містить насильство, дискримінацію, порнографію, або є образливим для певних груп людей.",
  },
  {
    title: "Опис каналу",
    description: "Давати чіткий і правдивий опис того, чим займається канал",
    allowed: "Давати чіткий і правдивий опис того, чим займається канал.",
    forbidden:
      "Включати неправдиву інформацію, посилання на шкідливі сайти або контент, що порушує політику UkrTube.",
  },
  {
    title: "Контактна інформація",
    description:
      "Включати реальні контактні дані, за якими можна зв’язатися з вами.",
    allowed:
      "Включати реальні контактні дані, за якими можна зв’язатися з вами.",
    forbidden: "Публікувати особисту інформацію інших людей без їхньої згоди.",
  },
  {
    title: "Назва каналу",
    description:
      "Використовувати унікальні назви, які не порушують авторські права і не є образливими.",
    allowed:
      "Використовувати унікальні назви, які не порушують авторські права і не є образливими.",
    forbidden:
      "Використовувати назви, що містять ненормативну лексику, порушують авторські права або вводять в оману щодо офіційного статусу каналу.",
  },

  {
    title: "Обкладинка каналу та аватар",
    allowed:
      "Використовувати оригінальні зображення або зображення, на які у вас є права.",
    forbidden:
      "Використовувати зображення, що порушують авторські права або містять ненависть, насильство, порнографію.",
  },
  {
    title: "Політика конфіденційності",
    allowed:
      "Дотримуватись політики конфіденційності UkrTube і не розкривайте особисту інформацію.",
    forbidden:
      "Збирати особисту інформацію без дозволу або використовувати її в шкідливих цілях.",
  },
];
const endIndexSubArr1 =
  rulesArray.length % 2 === 0
    ? rulesArray.length / 2
    : Math.floor(rulesArray.length / 2);
const subArray1 = rulesArray.slice(0, endIndexSubArr1);
const subArray2 = rulesArray.slice(endIndexSubArr1, rulesArray.length);

export default function Rules() {
  return (
    <div className="rules">
      <Back />
      <div className="rules-title-block">
        <h2 className="rules-title-h2">Правила додавання каналу</h2>
        <p className="rules-paragraph">
          Інформація, надана тут, призначена для клієнтів і користувачів
          UkrTube, які мають запитання щодо наших умов додавання каналу.
        </p>
      </div>

      <div className="rules-box-container">
        <div className="rules-box">
          {subArray1.map((rule) => (
            <RulePiece
              title={rule.title}
              description={rule.description}
              allowed={rule.allowed}
              forbidden={rule.forbidden}
            />
          ))}
        </div>

        <div className="rules-box">
          {subArray2.map((rule) => (
            <RulePiece
              title={rule.title}
              description={rule.description}
              allowed={rule.allowed}
              forbidden={rule.forbidden}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
