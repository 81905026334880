import React, { useState } from "react";
import "./dist/RulePiece.css";
import Icon from "../svg-icon/Icon";

export default function RulePiece(props) {
  console.log("props", props);
  const [show, setShow] = useState(false);

  const handleContentBox = () => setShow((prev) => !prev);

  return (
    <div
      className={`rules-content ${show ? "rules-content-show" : ""}`}
      onClick={handleContentBox}
    >
      <div className="rules-content-title">
        <h3 className="rules-content-title-h3">{props.title}</h3>
        <div
          className={`rules-content-icon-container ${
            show ? "rules-rotate-icon" : ""
          }`}
        >
          <Icon iconName="arrow-down" width="1.38vw" />
        </div>
      </div>

      <div className="rules-full-description">
        <div className="rules-full-description-title-block">
          <h3 className="rules-description-title">
            Дозволено <Icon width="1.6vw" iconName="approved-application" />
          </h3>
          <p>{props.allowed}</p>
        </div>

        <div className="rules-full-description-title-block">
          <h3 className="rules-description-title">
            Заборонено <Icon width="1.6vw" iconName="rejected-application" />
          </h3>
          <p>{props.forbidden}</p>
        </div>
      </div>
    </div>
  );
}
