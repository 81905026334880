import { createContext, useContext, useState } from "react";

const SaveSearchInfoContext = createContext();

export default function SearchInfoContextProvider({ children }) {
  const [saveInfo, setSaveInfo] = useState(false);
  const [allowToSave, setAllowToSave] = useState(false);
  return (
    <SaveSearchInfoContext.Provider
      value={{ saveInfo, setSaveInfo, allowToSave, setAllowToSave }}
    >
      {children}
    </SaveSearchInfoContext.Provider>
  );
}

export const useSearchInfoContext = () => {
  return useContext(SaveSearchInfoContext);
};
