import React from "react";
import "./dist/Loader.css";

export default function Loader() {
  return (
    <>
      <span className="loader"></span>
      <span className="text-loader">Зачекайте, будь ласка</span>
    </>
  );
}
