import React from "react";
import "./dist/Back.css";
import { useNavigate } from "react-router-dom";

export default function Back() {
  const nav = useNavigate();
  return (
    <div className="back-wrapper">
      <div className="back" onClick={() => nav("/")}>
        <svg
          className="back-icon"
          width="1.66vw"
          height="1.66vw"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25 9L8.75 1.5M1.25 9L8.75 16.5M1.25 9H18.75"
            stroke="black"
            strokeWidth="2.5"
          />
        </svg>

        <span className="back-text">На головну</span>
      </div>
    </div>
  );
}
