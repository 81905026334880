import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import SearchInfoContextProvider from "./context/SaveSearchInfoContext";
import DeniedAccess from "./pages/DeniedAccess/DeniedAccess";
import Rules from "./pages/Rules/Rules";

// import Home from "./pages/Home/Home";
// import Channels from "./pages/Channels/Channels";
// import Login from "./pages/Login/Login";
// import Register from "./pages/Register/Register";
// import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
// import AddChannel from "./pages/AddChannel/AddChannel";
// import ChooseCategories from "./pages/ChooseCategories/ChooseCategories";

const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));
const Register = lazy(() => import("./pages/Register/Register"));
const ForgetPassword = lazy(() =>
  import("./pages/ForgetPassword/ForgetPassword")
);
const AddChannel = lazy(() => import("./pages/AddChannel/AddChannel"));
const ChooseCategories = lazy(() =>
  import("./pages/ChooseCategories/ChooseCategories")
);

const StatusApplication = lazy(() =>
  import("./pages/StatusApplication/StatusApplication")
);
const ManageApplications = lazy(() =>
  import("./pages/Moderator/ManageApplications")
);
const ApplicationsForAdd = lazy(() =>
  import("./pages/Moderator/ApplicationsForAdd")
);
const ManageExistingApplications = lazy(() =>
  import("./pages/Moderator/ManageExistingApplications")
);

function App() {
  return (
    <BrowserRouter>
      <SearchInfoContextProvider>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Login />} path="/login" />
            <Route element={<Register />} path="/register" />
            <Route element={<ForgetPassword />} path="/forget-password" />
            <Route element={<AddChannel />} path="/add-channel" />
            <Route element={<ChooseCategories />} path="/choose-categories" />
            <Route element={<StatusApplication />} path="/application-status" />
            <Route element={<Rules />} path="/rules" />
            <Route element={<DeniedAccess />} path="*" />
            <Route element={<ManageApplications />} path="/moderator">
              <Route
                index
                element={<Navigate replace to="applications-add" />}
              />
              <Route element={<ApplicationsForAdd />} path="applications-add" />
              <Route
                element={<ManageExistingApplications />}
                path="manage-existing-applications"
              />
            </Route>
          </Routes>
        </Suspense>
      </SearchInfoContextProvider>
    </BrowserRouter>
  );
}

export default App;
